import { useEffect, useState } from 'react';
import { protectedResources } from '../authConfig';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, Row, Alert } from 'react-bootstrap';

export const ServiceBus = () => {
    const { instance } = useMsal();
    const [numMessages, setNumMessages] = useState(0);
    const [messageBody, setMessageBody] = useState('');
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const account = instance.getActiveAccount();

    const request = {
        scopes: protectedResources.dotNetApi.scopes,
        account: account,
    };

    const { login, result, error } = useMsalAuthentication(InteractionType.Popup, {
        ...request,
        redirectUri: '/redirect.html',
    });

    useEffect(() => {
        if (!!error) {
            // in case popup is blocked, use redirect instead
            if (error.errorCode === 'popup_window_error' || error.errorCode === 'empty_window_error') {
                login(InteractionType.Redirect, request);
            }

            console.log(error);
            return;
        }
        // eslint-disable-next-line
    }, [login, result, error, instance]);



    const handleAlert = () => {
        setMessage('');
        setShowMessage(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowMessage(false);
        setMessage('');

        if (numMessages === 0) {
            setMessage("No message to send");
            setShowMessage(true);
            return;
        }
        var log = '';
        for (let index = 0; index < numMessages; index++) {
            // const element = array[index];
            let response = null;
            const headers = new Headers();
            const bearer = `Bearer ${result.accessToken}`;
            headers.append("Authorization", bearer);
            headers.append("Ocp-Apim-Subscription-Key", "ad6c8d1b97ea4c38a068e4c7aaa45a9b");
            headers.append("Content-Type", "application/json");
            let options = {
                method: 'POST',
                headers: headers,
                body: messageBody
            };
            const endpoint = "https://apimdev.banchio.net/banchioapi/dotnet/sendMessage";

            response = await fetch(endpoint, options);

            console.log('Message number ' + index + ' response: ' +  response.status);
            log += message + ' Message number ' + index + ' response: ' +  response.status;
        }
        setMessage(log);
        setShowMessage(true);

    };

    const handleMessageBodyChange = (e) => {
        e.preventDefault();
        setMessageBody(e.target.value);
    };

    const handleNumMessagesChange = (e) => {
        e.preventDefault();
        setNumMessages(e.target.value);
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <Container>
            <Row>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Control type="text" name="numMessages" placeholder="Number of Messages" onChange={handleNumMessagesChange} />
                        <Form.Control type="text" name="messagebody" placeholder="message body" onChange={handleMessageBodyChange} />
                    </Form.Group>
                    <Button type="submit">Send</Button>
                </Form>
            </Row>
            {showMessage ? (
                <Row>
                    <div className="data-area-div" onClick={handleAlert}>
                        <Alert variant="success">
                            <p>{message}</p>
                        </Alert>
                    </div>
                </Row>
            ) : null}
        </Container>
    );
};
