import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { protectedResources } from '../authConfig';
import { useEffect, useState } from 'react';
import { DotnetUserInfoDisplay } from '../components/DotnetUserInfoDisplay';

export const DotNetUserInfoApi = () => {
    const { instance } = useMsal();
    const [dotnetUserInfo, setdotnetUserInfo] = useState(null);
    const account = instance.getActiveAccount();
    const request = {
        scopes: protectedResources.dotNetApi.scopes,
        account: account,
    };

    const { login, result, error } = useMsalAuthentication(InteractionType.Popup, {
        ...request,
        redirectUri: '/redirect.html',
    });

    const fetchData = async (accessToken) => {
        let response = null;
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Ocp-Apim-Subscription-Key","ad6c8d1b97ea4c38a068e4c7aaa45a9b");
        let options = {
            method: 'GET',
            headers: headers,
            body: null
        };
        const endpoint = "https://apimdev.banchio.net/banchioapi/dotnet/userInfo";
        response = await (await fetch(endpoint, options)).json();
        setdotnetUserInfo(response);
    };

    useEffect(() => {
        if (!!dotnetUserInfo) {
            return;
        }

        if (!!error) {
            // in case popup is blocked, use redirect instead
            if (error.errorCode === 'popup_window_error' || error.errorCode === 'empty_window_error') {
                login(InteractionType.Redirect, request);
            }

            console.log(error);
            return;
        }

        if (result) {
            fetchData(result.accessToken).catch((error) => {
                console.log(error);
            });
        }
        // eslint-disable-next-line
    }, [dotnetUserInfo, result, error, login]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    return <>{dotnetUserInfo ? <DotnetUserInfoDisplay response={result} dotnetUserInfo={dotnetUserInfo} /> : null}</>;
}
