import { Container,  Form,  Row } from 'react-bootstrap';
import { JSONTree } from 'react-json-tree';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { protectedResources } from '../authConfig';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';


export const DotnetQueryDataDisplay = () => {
    const { instance } = useMsal();
    const [dotnetSqlResult, setdotnetSqlResult] = useState('{}');
    const [tableName, setTableName] = useState('serviceBusMessage')
    const [schemaName, setSchemaName] = useState('dbo')
    const [recordCount, setRecordCount] = useState(20)
    const account = instance.getActiveAccount();
    const request = {
        scopes: protectedResources.dotNetApi.scopes,
        account: account,
    };

    const { login, result, error } = useMsalAuthentication(InteractionType.Popup, {
        ...request,
        redirectUri: '/redirect.html',
    });

    const fetchData = async (accessToken) => {
        let response = null;
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Ocp-Apim-Subscription-Key","ad6c8d1b97ea4c38a068e4c7aaa45a9b");
        let options = {
            method: 'GET',
            headers: headers,
            body: null
        };
        // const endpoint = `https://apimdev.banchio.net/banchioapi/dotnet/query?tableName=${tableName}&recordCount=${recordCount}&schemaName=${schemaName}`;
        const endpoint = `https://apimdev.banchio.net/banchioapi/dotnet/query?tableName=${tableName}&recordCount=${recordCount}&schemaName=${schemaName}`;
        // response = await (await fetch('http://20.246.247.254/api/azure/secret', options)).json();
        response = await (await fetch(endpoint, options)).json();
        setdotnetSqlResult(response);
    };

    useEffect(() => {
        if (!!error) {
            // in case popup is blocked, use redirect instead
            if (error.errorCode === 'popup_window_error' || error.errorCode === 'empty_window_error') {
                login(InteractionType.Redirect, request);
            }

            console.log(error);
            return;
        }
        // eslint-disable-next-line
    }, [login, result, error, instance]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        fetchData(result.accessToken);
        
    }

    const onInputChange = (e) => 
    {
        if (e.nativeEvent.srcElement.name==="tableName") {
             setTableName(e.nativeEvent.srcElement.value);
        }
        else if (e.nativeEvent.srcElement.name==="recordCount") {
             setRecordCount(e.nativeEvent.srcElement.value);
        }
        else {
            setSchemaName(e.nativeEvent.srcElement.value);
        }
      }

    return (
        <Container>
            <Row>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Control type="Text" placeholder="schemaname" name='schemaName' id='schemaName' onChange={onInputChange} value={schemaName} />
                        <Form.Control type="Text" placeholder="tablename" name='tableName' id='tableName' onChange={onInputChange} value={tableName} />
                        <Form.Control type="Text" placeholder="recordCount" name='recordCount' id='recordCount' onChange={onInputChange} value={recordCount} />
                    </Form.Group>
                    <Button type="submit">Query</Button>
                </Form>
            </Row>
            <Row>
                <JSONTree data={dotnetSqlResult} />
            </Row>
        </Container>
    );
}
