import { Container,  Row } from 'react-bootstrap';
import { JSONTree } from 'react-json-tree';


export const DotnetSecretDataDisplay = ({ dotnetSecretInfo, response }) => {
    return (
        <Container>
            <Row>

                    <JSONTree data={dotnetSecretInfo} />
            </Row>
        </Container>
    );
}