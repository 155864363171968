import { Container,  Row } from 'react-bootstrap';
import { JSONTree } from 'react-json-tree';


export const DotnetUserInfoDisplay = ({ dotnetUserInfo, response }) => {
    return (
        <Container>
            <Row>

                    <JSONTree data={dotnetUserInfo} />
            </Row>
        </Container>
    );
}